function new_map($el) {
  // var
  var $markers = $el.querySelectorAll(".marker");

  // vars
  var args = {
    zoom: 10,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            gamma: 0.5,
          },
        ],
      },
    ],
  };

  // create map
  var map = new google.maps.Map($el, args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.forEach(function ($marker) {
    add_marker($marker, map);
  });

  // center map
  center_map(map);

  // return
  return map;
}

function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    parseFloat($marker.getAttribute("data-lat")),
    parseFloat($marker.getAttribute("data-lng"))
  );

  // icon
  var icon = {
    url: themePath + "/src/images/symbols/marker.svg",
    scaledSize: new google.maps.Size(35, 45),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(14, 20),
  };

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: icon,
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($marker.innerHTML) {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content: $marker.innerHTML,
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  }
}

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  map.markers.forEach(function (marker) {
    var latlng = marker.position;

    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length === 1) {
    // get the current center
    var theCenter = bounds.getCenter();

    // set center of map
    map.setCenter({ lat: theCenter.lat(), lng: theCenter.lng() - 0.006 });
    map.setZoom(16);
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}

var map = null;

document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".acf-map").forEach(function ($map) {
    // create map
    map = new_map($map);
  });
});
