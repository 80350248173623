document.querySelectorAll(".audio-button").forEach(function (button) {
  button.addEventListener("click", function () {
    this.classList.toggle("playing");

    document.querySelectorAll(".audio-button").forEach(function (otherButton) {
      if (otherButton !== button) {
        otherButton.classList.remove("playing");
      }
    });

    var audioThis = this.nextElementSibling;

    if (audioThis.paused) {
      audioThis.play();

      document.addEventListener(
        "play",
        function (e) {
          var otherAudio = document.getElementsByTagName("audio");
          for (var i = 0, len = otherAudio.length; i < len; i++) {
            if (otherAudio[i] !== e.target) {
              otherAudio[i].pause();
            }
          }
        },
        true
      );
    } else {
      audioThis.pause();
    }
  });
});
