import "./style.scss";

import Router from "./javascripts/util/Router";
import common from "./javascripts/routes/common";
import audioplayer from "./javascripts/routes/audio-player";
// import home from "./javascripts/routes/home";
// import about from "./javascripts/routes/about";
import "./javascripts/flexible-content/map";
import "./javascripts/flexible-content/coworker";

// Calcualte js-header-banner height and assign it to css variable --top-header-banner-height
// Debounce function to limit the rate at which a function can fire
const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// Function to maintain header banner height top margin
const maintainHeaderBannerHeightTopMargin = () => {
  const headerBanner = document.querySelector(".js-header-banner");
  if (!headerBanner) return;

  const headerBannerHeight = headerBanner.getBoundingClientRect().height;
  document.body.style.setProperty(
    "--var-top-header-bar-height",
    headerBannerHeight + "px"
  );
};

// Debounce the resize function to trigger only when resize is completed
const debouncedMaintainHeader = debounce(
  maintainHeaderBannerHeightTopMargin,
  250
); // 250ms delay

// Initial function call
maintainHeaderBannerHeightTopMargin();
// Add event listener with the debounced function
window.addEventListener("resize", debouncedMaintainHeader);
window.addEventListener("load", maintainHeaderBannerHeightTopMargin);

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  audioplayer,
  /** Home page */
  // home,
  /** About Us page, note the change from about-us to aboutUs. */
  // about,
});

/** Load Events */
routes.loadEvents();
